<template>
  <div>
    <v-data-table
      class="activity-table-wrap"
      disable-sort
      hide-default-footer
      :headers="headers"
      :items="items"
      :items-per-page="pagination"
    >
      <template v-if="!loading && items.length === 0" v-slot:no-data>
        <div
          class="no-data-wrap d-flex flex-column align-center justify-center py-10 px-5"
        >
          <ph-vault :size="80" :color="'#bfbfbf'" />
          <block-text
            :text="
              $t('activities.table.noDataTitle', {
                dateFilterVal: last30 ? 30 : formattedDateFilter,
              })
            "
            class="pt-6"
            align="center"
            color="primary"
            type="headline"
          />
          <block-text
            :text="$t('activities.table.noDataDescription')"
            class="pt-3"
            align="center"
            color="secondary"
            type="body-1"
          />
        </div>
      </template>
      <template v-slot:body="{ items }" v-if="items.length">
        <tbody>
          <tr
            v-for="(item, itemIndex) in items"
            :key="itemIndex"
            @click="toggleInfoDialog(item)"
            @mouseenter="hoverOn(itemIndex)"
            @mouseleave="hoverOff(itemIndex)"
            height="50"
            class="cursor-pointer rounded-xl"
            :class="[
              item.hoverOn ? ' padding-on' : '',
              item.hoverOff ? ' padding-off' : '',
            ]"
          >
            <td class="px-3">
              <div class="d-flex align-center">
                <div
                  v-if="isMasterWallet(item.user)"
                  class="d-flex align-center"
                >
                  <v-img max-width="30" :src="require('@/assets/logo.svg')" />
                  <block-text
                    :text="item.user"
                    color="secondary"
                    type="caption"
                    class="mx-3"
                  />
                </div>
                <div v-else class="d-flex align-center">
                  <v-avatar color="rgba(190, 191, 192, 0.25)" size="30">
                    <v-img
                      v-if="item.userImage"
                      max-width="30"
                      :src="item.userImage"
                    />
                    <ph-user
                      v-else
                      :size="20"
                      :color="'rgba(128, 128, 129, 1)'"
                    />
                  </v-avatar>

                  <block-text
                    v-if="isMyCommunity(item.user, item.type)"
                    :text="$t('activities.table.myCommunity')"
                    color="secondary"
                    type="caption"
                    class="mx-3"
                  />
                  <block-text
                    v-else
                    :text="item.user"
                    color="primary"
                    weight="bold"
                    type="caption"
                    class="mx-3"
                  />
                </div>
              </div>
            </td>
            <td class="px-5">
              <div class="d-flex flex-column">
                <block-text
                  :text="item.dateTime | convertToLocalDateShortDay"
                  color="secondary"
                  type="caption"
                />
              </div>
            </td>
            <td class="px-5">
              <div
                v-if="item.status.toLowerCase() === 'completed'"
                class="d-flex align-center"
              >
                <ph-check-circle
                  :size="16"
                  :color="$vuetify.theme.dark ? '#fff' : '#222'"
                />
                <div class="d-flex flex-column mx-2">
                  <block-text
                    :text="item.status"
                    color="primary"
                    weight="bold"
                    type="caption"
                  />
                </div>
              </div>
              <div
                v-else-if="item.status.toLowerCase() === 'pending'"
                class="status-and-type-wrap d-flex align-center"
              >
                <ph-arrows-clockwise
                  class="pending-icon"
                  :size="16"
                  :color="'#888'"
                />
                <div class="d-flex flex-column mx-2">
                  <block-text
                    :text="item.status"
                    color="secondary"
                    weight="bold"
                    type="caption"
                  />
                </div>
              </div>
              <div
                v-else-if="item.status.toLowerCase() === 'rejected'"
                class="status-and-type-wrap d-flex align-center"
              >
                <ph-x-circle :size="16" :color="'#888'" />
                <div class="d-flex flex-column mx-2">
                  <block-text
                    :text="item.status"
                    color="secondary"
                    weight="bold"
                    type="caption"
                  />
                </div>
              </div>
              <div
                v-else-if="item.status.toLowerCase() === 'expired'"
                class="status-and-type-wrap d-flex align-center"
              >
                <ph-warning-circle :size="16" :color="'#888'" />
                <div class="d-flex flex-column mx-2">
                  <block-text
                    :text="item.status"
                    color="secondary"
                    weight="bold"
                    type="caption"
                  />
                </div>
              </div>
            </td>
            <td class="px-5">
              <div class="d-flex">
                <type-icon :type="item.type" />
                <div class="d-flex flex-column mx-2">
                  <block-text
                    :text="item.type"
                    color="secondary"
                    type="caption"
                  />
                </div>
              </div>
            </td>
            <td class="px-5" width="25%">
              <div
                class="amount-wrap d-flex align-center justify-end"
                :class="[
                  !isMasterWallet(item.user) &&
                  !isMyCommunity(item.user, item.type)
                    ? 'completed'
                    : 'pending',
                  !isMasterWallet(item.user) &&
                  !isMyCommunity(item.user, item.type) &&
                  item.direction.toLowerCase() === 'income'
                    ? 'income'
                    : 'outgoing',
                  isMasterWallet(item.user) ||
                  isMyCommunity(item.user, item.type)
                    ? 'master-wallet'
                    : '',
                ]"
              >
                <div class="ttu-count d-flex align-center py-2">
                  <block-text
                    :text="
                      item.amount > 0
                        ? '+' + item.amount.toFixed(2)
                        : item.amount.toFixed(2)
                    "
                    color="primary"
                    weight="bold"
                    type="body-1"
                  />
                  <div class="ml-2">
                    <v-img
                      class="ttu-coin-img rounded-circle"
                      width="20"
                      height="20"
                      :src="require('@/assets/ttuToken.svg')"
                    />
                  </div>
                </div>
                <div
                  v-if="
                    !isMyCommunity(item.user, item.type) &&
                    !isMasterWallet(item.user)
                  "
                  class="send-ttu align-center py-2"
                >
                  <ph-paper-plane-tilt
                    :size="20"
                    :color="$vuetify.theme.dark ? '#fff' : '#222'"
                    @click.stop="toggleDialog(item.otherWalletId)"
                  />
                </div>
                <div
                  v-else-if="
                    !isMyCommunity(item.user, item.type) &&
                    !isMasterWallet(item.user) &&
                    item.direction.toLowerCase() === 'income'
                  "
                  class="accept-reject d-flex align-center pl-7"
                >
                  <v-btn
                    width="78"
                    height="30"
                    text
                    :color="$vuetify.theme.dark ? '#fff' : '#222'"
                    plain
                    :opacity="1"
                    class="mx-2 rounded-lg body-1 bold"
                    @click.stop="toggleRejectDialog(item)"
                    >{{ $t("activities.table.reject") }}</v-btn
                  >
                  <v-btn
                    width="78"
                    height="30"
                    text
                    :color="$vuetify.theme.dark ? '#fff' : '#222'"
                    plain
                    :opacity="1"
                    class="mx-2 rounded-lg body-1 bold"
                    @click.stop="toggleAcceptDialog(item)"
                    >{{ $t("activities.table.accept") }}</v-btn
                  >
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-col cols="12" v-if="loading">
      <v-skeleton-loader
        width="100%"
        class="pt-0"
        type="table"
        :types="{
          'table-row': 'table-cell@4',
          'table-heading': false,
          'table-thead': false,
          'table-tfoot': false,
        }"
      />
    </v-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dateConvertor from "@/mixins/dateConvertor";
import {
  PhUser,
  PhCheckCircle,
  PhArrowsClockwise,
  PhXCircle,
  PhWarningCircle,
  PhPaperPlaneTilt,
  PhVault,
} from "phosphor-vue";
export default {
  components: {
    TypeIcon: () => import("@/components/TypeIcon"),
    PhUser,
    PhCheckCircle,
    PhArrowsClockwise,
    PhXCircle,
    PhWarningCircle,
    PhPaperPlaneTilt,
    PhVault,
  },
  mixins: [dateConvertor],
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Number,
      required: true,
    },
    direction: {
      type: String,
      required: false,
      default: "",
    },
    singleTable: {
      type: Boolean,
      required: false,
      default: false,
    },
    last30: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  filters: {
    formatted(value) {
      if (typeof value === "string") return "90+";
      return value;
    },
  },
  data() {
    return {
      enabled: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "wallet/user",
      currentDateFilter: "wallet/currentDateFilter",
    }),
    formattedDateFilter() {
      return this.$options.filters.formatted(this.currentDateFilter);
    },
  },
  created() {
    this.$store.commit("wallet/setCurrentDateFilter", 30);
  },
  methods: {
    isMasterWallet(user) {
      return (
        user.toLowerCase() === "master wallet" ||
        user.toLowerCase() === "tatatu enterprise ltd (master wallet)"
      );
    },
    isMyCommunity(user, type) {
      return !user && type.toLowerCase() === "donations";
    },
    hoverOn(itemIndex) {
      this.items[itemIndex].hoverOn = true;
      this.items[itemIndex].hoverOff = false;
    },
    hoverOff(itemIndex) {
      this.items[itemIndex].hoverOff = true;
      this.items[itemIndex].hoverOn = false;
    },
    toggleDialog(otherWalletId) {
      this.$eventHub.$emit("toggleDialog", {
        isOpen: true,
        headerIcon: "PhPaperPlaneTilt",
        dialogPath: "components/Dialogs/SendTTUDialog",
        title: this.$t("dialog.sendTTU"),
        payload: {
          otherWalletId: otherWalletId,
        },
      });
    },
    toggleInfoDialog(transaction) {
      let dialogTitle = "";
      let dialogIcon = "";
      switch (transaction.status) {
        case "Completed":
          dialogTitle = this.$t("dialog.transactionCompleted");
          dialogIcon = "PhCheckCircle";
          break;
        case "Pending":
          dialogTitle = this.$t("dialog.transactionPending");
          dialogIcon = "PhArrowsClockwise";
          break;
        case "Rejected":
          dialogTitle = this.$t("dialog.transactionRejected");
          dialogIcon = "PhXCircle";
          break;
        case "Expired":
          dialogTitle = this.$t("dialog.transactionExpired");
          dialogIcon = "PhWarningCircle";
          break;
        default:
          dialogTitle = this.$t("dialog.transactionCompleted");
          dialogIcon = "PhCheckCircle";
      }
      this.$eventHub.$emit("toggleDialog", {
        isOpen: true,
        headerIcon: dialogIcon,
        dialogPath: "components/Dialogs/TransactionInfoDialog",
        title: dialogTitle,
        payload: transaction,
      });
    },
    toggleRejectDialog(transaction) {
      this.$eventHub.$emit("toggleDialog", {
        isOpen: true,
        dialogPath: "components/Dialogs/ConfirmDialog",
        payload: {
          dialogType: "reject",
          dialogIcon: "PhXCircle",
          transaction: transaction,
          text1: this.$t("dialog.rejectText1", {
            amount: transaction.amount.toFixed(2),
            user: transaction.user,
          }),
          text2: this.$t("dialog.rejectText2", {
            amount: transaction.amount.toFixed(2),
            user: transaction.user,
          }),
          ctaText: this.$t("dialog.confirm"),
          direction: this.direction,
          singleTable: this.singleTable,
        },
      });
    },
    toggleAcceptDialog(transaction) {
      this.$eventHub.$emit("toggleDialog", {
        isOpen: true,
        dialogPath: "components/Dialogs/ConfirmDialog",
        payload: {
          dialogType: "accept",
          dialogIcon: "PhCheckCircle",
          transaction: transaction,
          text1: this.$t("dialog.acceptText1", {
            amount: transaction.amount.toFixed(2),
            user: transaction.user,
          }),
          text2: this.$t("dialog.acceptText2", {
            amount: transaction.amount.toFixed(2),
            user: transaction.user,
          }),
          ctaText: this.$t("dialog.confirm"),
          direction: this.direction,
          singleTable: this.singleTable,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-skeleton-loader ::v-deep .v-skeleton-loader__table-heading,
.v-skeleton-loader ::v-deep .v-skeleton-loader__table-thead,
.v-skeleton-loader ::v-deep .v-skeleton-loader__table-tbody,
.v-skeleton-loader ::v-deep .v-skeleton-loader__table-tfoot {
  background-color: transparent;
}

.v-skeleton-loader ::v-deep .v-skeleton-loader__table-heading,
.v-skeleton-loader ::v-deep .v-skeleton-loader__table-thead {
  padding: 0;
}

.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover)::v-deep
  .v-btn__content {
  opacity: 1;
}

.activity-table-wrap {
  .v-avatar svg {
    width: 24px;
    height: 24px;
  }

  .pending-icon {
    animation: rotating 3s linear infinite;
  }

  .amount-wrap {
    &.pending.income {
      .ttu-count {
        padding-right: 40px;
      }
    }

    &.completed {
      .ttu-count {
        padding-right: 0px;
      }

      &.master-wallet {
        .ttu-count {
          padding: 0;
        }
        .send-ttu {
          display: none;
        }
      }
    }

    .send-ttu {
      display: none;
    }

    .ttu-coin-img {
      box-shadow: $box-shadow-light;
    }

    .accept-reject {
      border-left: 1px solid var(--v-border-base);
      .v-btn {
        border: 1px solid var(--v-btnBorder-base);
      }
    }
  }

  .no-data-wrap {
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
  }
}

.v-data-table {
  ::v-deep .v-data-table__wrapper {
    overflow: visible;
    table {
      border-collapse: separate;
      border-spacing: 0 5px;
      td {
        border: none !important;
      }
      thead tr {
        height: 30px;
        th {
          height: 20px;
          border: none !important;
          padding: 0px 20px;
          font-weight: 400;
          span {
            font-size: 12px;
            color: var(--v-secondary-base);
          }
        }
      }

      tbody {
        tr {
          td {
            background-color: var(--v-lightgrey-base);
            transition: all 0.15s ease-in-out;
          }
          td:first-child {
            border-top-left-radius: $radius-xl;
            border-bottom-left-radius: $radius-xl;
          }
          td:last-child {
            border-top-right-radius: $radius-xl;
            border-bottom-right-radius: $radius-xl;
          }

          &.padding-on {
            .amount-wrap {
              &.completed {
                &:not(.master-wallet) {
                  .ttu-count {
                    animation: paddingRightStart 0.15s linear;
                    animation-fill-mode: forwards;
                  }
                }
              }
              &:not(.master-wallet) {
                .send-ttu {
                  display: flex;
                  border-left: 1px solid var(--v-border-base);
                  animation: paddingLeftStart 0.15s linear;
                  animation-fill-mode: forwards;
                }
              }
            }
          }

          &.padding-off {
            .amount-wrap {
              &.completed {
                &:not(.master-wallet) {
                  .ttu-count {
                    animation: paddingRightStop 0.15s linear;
                    animation-fill-mode: forwards;
                  }
                }
              }
              &:not(.master-wallet) {
                .send-ttu {
                  display: none;
                  border-left: none;
                  animation: paddingLeftStop 0.15s linear;
                  animation-fill-mode: forwards;
                }
              }
            }
          }

          &.v-data-table__empty-wrapper {
            border-radius: $no-radius;
            cursor: auto;
            box-shadow: none;
            td {
              border-radius: $no-radius;
              background-color: transparent;
            }
          }

          &:hover {
            &:not(.v-data-table__empty-wrapper) {
              box-shadow: $box-shadow-light;
              td {
                background-color: var(--v-appBcg-base);
              }
            }
          }

          &:first-child:hover {
            td {
              &:first-child {
                border-top-left-radius: $radius-xl;
                border-bottom-left-radius: $radius-xl;
              }
              &:last-child {
                border-top-right-radius: $radius-xl;
                border-bottom-right-radius: $radius-xl;
              }
            }
          }

          &:last-child:hover {
            td {
              &:first-child {
                border-top-left-radius: $radius-xl;
                border-bottom-left-radius: $radius-xl;
              }
              &:last-child {
                border-top-right-radius: $radius-xl;
                border-bottom-right-radius: $radius-xl;
              }
            }
          }
        }
      }
    }
  }
  &.theme--dark {
    background-color: var(--v-appBcg-base);
    &
      > .v-data-table__wrapper
      > table
      > tbody
      > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
      background-color: var(--v-appBcg-base);
      box-shadow: $box-shadow-dark;
    }
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes paddingLeftStart {
  20% {
    padding-left: 3px;
  }
  40% {
    padding-left: 7px;
  }
  60% {
    padding-left: 15px;
  }
  80% {
    padding-left: 26px;
  }
  100% {
    padding-left: 40px;
  }
}

@keyframes paddingLeftStop {
  20% {
    padding-left: 37px;
  }
  40% {
    padding-left: 33px;
  }
  60% {
    padding-left: 25px;
  }
  80% {
    padding-left: 14px;
  }
  100% {
    padding-left: 0px;
  }
}

@keyframes paddingRightStart {
  20% {
    padding-right: 3px;
  }
  40% {
    padding-right: 7px;
  }
  60% {
    padding-right: 15px;
  }
  80% {
    padding-right: 26px;
  }
  100% {
    padding-right: 40px;
  }
}

@keyframes paddingRightStop {
  20% {
    padding-right: 37px;
  }
  40% {
    padding-right: 33px;
  }
  60% {
    padding-right: 25px;
  }
  80% {
    padding-right: 14px;
  }
  100% {
    padding-right: 0px;
  }
}
</style>
